import React from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Select from './components/select'
import Payment from './components/payment'
import Vaccine from './components/vaccine'
import RProTax from './components/rpt'
import Success from './components/success'
import Error from './components/error'
import NotFound from './NotFound'

class Main extends React.Component {
  state = {
    toggled: false,
  }

  togglePanel = () => this.setState({ toggled: !this.state.toggled })

  render () {
    global.RG = { currentUser: JSON.parse(sessionStorage.user || '{}') }

    return (
      <Router history={this.props.history}>
        <div className="wrapper wrapper-login wrapper-login-full p-0">
          <Switch>
            <Route exact path="/" component={Select} />
            <Route exact path="/payments" component={(Payment)} />
            <Route exact path="/vaccine" component={(Vaccine)} />
            <Route exact path="/rpt" component={(RProTax)} />
            <Route exact path="/success" component={(Success)} />
            <Route exact path="/error" component={(Error)} />
            <Route component={NotFound} />
          </Switch>
        </div>
      </Router>
    )
  }
}

export default withRouter(
  connect(state => ({
    error: state.payments.error,
    success_message: state.payments.success_message,
  }), {})(Main)
)
