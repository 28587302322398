import * as ACTIONS from '../actions/actionTypes'

const initial = {
  loading: false,
  success: false,
  success_message: null,
  invoice_amount: 0,
  line_item: null,
  transaction: {},
  error: null,
  data: [],
}

export default (state = initial, { type, payload }) => {
  switch (type) {
    case ACTIONS.GET_REQUEST_SUCCESS:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case ACTIONS.GET_RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: payload.response.data.error
      }
    case ACTIONS.CUSTOM_ERROR:
      return {
        ...state,
        loading: false,
        error: payload.response
      }
    case ACTIONS.CREATE_INVOICE:
      return {
        ...state,
        loading: false,
        error: null,
        success_message: 'Invoice Successfully Created.',
        success: true
      }
    case ACTIONS.CREATE_TRANSATION:
      return {
        ...state,
        loading: false,
        error: null,
        success_message: 'Payment Successful',
        success: true,
        transaction: payload.response,
        line_item: null,
      }
    case ACTIONS.GET_ALL_REVENUE:
      return {
        ...state,
        loading: false,
        line_items: payload.response
      }
    case ACTIONS.GET_PARTICULAR_REVENUE:
      return {
        ...state,
        loading: false,
        error: null,
        invoice_amount: payload.response.invoice_amount,
        line_item: payload.response.revenue_line_item
      }
    default:
      return { ...state, loading: false }
  }
}
