import React from 'react'
import img from '../../assets/images/loader.gif'

const style = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.1)',
  zIndex: 1
}

export default function Loader ({ loading }) {
  if (!loading) return null

  return (
    <div style={style}>
      <img src={img} alt='spinner' />
    </div>
  )
}
