import React from 'react'

import CoatOfArms from '../../assets/images/logo.png'

export default ({ background, children, image, imageAlt, title, titleCls, subtitle }) => {
  const h1 = title || 'Ministry of Finance Payment Platform'
  const h1c = titleCls || 'title fw-bold text-white mb-3'

  const sub = subtitle || (
    <p className='subtitle text-white op-7'>Pay for all of your government services.</p>
  )

  const leftCls = background
    ? 'login-aside w-50'
    : 'login-aside w-50 bg-secondary-gradient'

  const leftStyle = background
    ? { background }
    : undefined

  return (
    <React.Fragment>
      <div className={leftCls} style={leftStyle}>
        <div className='left-container d-flex flex-column align-items-center justify-content-center text-center'>
          <div className='div-logo' style={{ margin: 0 }}>
            <img src={CoatOfArms} alt='The Bahamas Coat Of Arms' className='coat-of-arms logo-img' />
          </div>
          <h1 className={h1c}>{h1}</h1>
          {sub}
        </div>
      </div>
      <div className='login-aside w-50 d-flex align-items-center justify-content-center'>
        <div className='container container-login container-transparent animated fadeIn'>
          <div className='card card-post card-round'>
            <img className='card-img-top' src={image} alt={imageAlt || 'Card Top Image'} />
            <div className='card-body'>
              {children}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
