import axios from 'axios'

import * as ACTIONS from './actionTypes'
import { encryptRsakey } from '../components/helpers/RsaEncryption'
import { guestHeaders } from '../components/helpers/Tools'

const getRequest = {
  type: ACTIONS.GET_REQUEST_SUCCESS,
}

const createError = error => ({
  type: ACTIONS.GET_RESPONSE_ERROR,
  payload: error
})

export const getAllRevenue = () => async dispatch => {
  const headers = await guestHeaders()

  try {
    const { data } = await axios.get(`${process.env.REACT_APP_API_HOST}/api/v1/revenue_line_items`, { headers })
    dispatch({
    type: ACTIONS.GET_ALL_REVENUE,
    payload: { response: data.revenue_line_items }
  })
  } catch (err) {
    dispatch(createError(err))
  }
}

export const getParticularRevenue = invoice => async dispatch => {
  const headers = await guestHeaders()

  try {
    dispatch(getRequest)
    const { data } = await axios.get(`${process.env.REACT_APP_API_HOST}/api/v1/revenue_line_items/by_invoice_number/${invoice}`, { headers })

    dispatch({
      type: ACTIONS.GET_PARTICULAR_REVENUE,
      payload: { response: data }
    })
  } catch (err) {
    dispatch(createError(err))
  }
}

export const createInvoice = (invoice, cb) => async dispatch => {
  dispatch(getRequest)
  invoice.ip_address = 'payments_web'

  try {
    const headers = await guestHeaders()
    const { data } = await axios.post(`${process.env.REACT_APP_API_HOST}/api/v1/invoices`, { invoice }, { headers })

    dispatch({ type: ACTIONS.CREATE_INVOICE })
    typeof cb == 'function' && cb(null, data)
  } catch (err) {
    dispatch({
      type: ACTIONS.CUSTOM_ERROR,
      payload: {
        response: err.response.data.error,
      }
    })

    typeof cb == 'function' && cb(err, null)
  }
}

export const saveTransaction = (form, cb) => async dispatch => {
  delete form['publisher-name']
  form.ip_address = 'payments_web'

  try {
    const { data } = await axios.get(`${process.env.REACT_APP_API_HOST}/api/v1/fetch_rsa_key`)
    const { public_key, rsa_token } = data.rsa_key

    form['card-number'] = await encryptRsakey(public_key, form['card-number'])
    form['card-cvv'] = await encryptRsakey(public_key, form['card-cvv'])
    form['card-name'] = await encryptRsakey(public_key, form['card-name'])
    form['card-exp'] = await encryptRsakey(public_key, form['card-exp'])
    form['card-amount'] = await encryptRsakey(public_key, form['card-amount'])
    form['rsa_token'] = rsa_token
  } catch (err) {
    dispatch(createError(err))
  }

  dispatch(getRequest)
  const headers = await guestHeaders()

  try {
    const { data } = await axios.post(`${process.env.REACT_APP_API_HOST}/api/v1/transactions`, form, { headers })
    const success = data.status === 200

    const obj = success ? {
      type: ACTIONS.CREATE_TRANSATION,
      payload: { response: data }
    } : {
      type: ACTIONS.CUSTOM_ERROR,
      payload: { response: data.error }
    }

    dispatch(obj)
    typeof cb == 'function' && cb(success)
  } catch (err) {
    dispatch(createError(err))
  }
}
