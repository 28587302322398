import React, { useEffect } from 'react'
import { confirmAlert } from 'react-confirm-alert'

export default function PayMethodSelect (props) {
  useEffect(() => {
    const go = (close, path) => () => {
      close()

      if (/^https?:/.test(path)) {
        window.location = path
      } else {
        props.history.push(path, {
          redirected: true
        })
      }
    }

    confirmAlert({
      closeOnClickOutside: false,
      closeOnEscape: false,

      customUI: ({ onClose }) => (
        <div className='custom-ui'>
          <h3>Will you be making a Credit/Debit Card or Sand Dollar payment today?</h3>
          <button onClick={go(onClose, '/payments')}>Credit/Debit Card</button>
          <button onClick={go(onClose, process.env.REACT_APP_SANDDOLLAR_PYMT)}>Sand Dollar</button>
        </div>
      )
    })
  })

  return null
}