import React from 'react';
import { Provider } from 'react-redux';
import reducer from './reducers';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'react-router-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import Main from './Main'

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css'
import './assets/css/demo.css'
import 'react-confirm-alert/src/react-confirm-alert.css'
import 'react-responsive-modal/styles.css'
import 'react-toastify/dist/ReactToastify.css'

import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();
const historyMiddleware = routerMiddleware(history);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(historyMiddleware, thunk))
);


export default () => (
  <Provider store={store}>
    <Main history={history}/>
  </Provider>
);


