import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'

import Template from './templates/default'
import CardImage from '../assets/images/payment-screen-card.jpg'
import { BigCheckmark } from './base64-images'

class Success extends React.Component {
  componentDidCatch() {
    this.props.history.push("/payments")
  }

  render() {
    const { transaction } = this.props

    return (
      <Template image={CardImage}>
        <div className='text-center'>
          <img src={BigCheckmark} alt='Check Icon' style={{ width: '3rem' }} />
          <br />
          <span className="title">Success</span>
          <div className="response-data">
            <span >Revenue Line Item:
              <span className="value">{transaction.description}</span>
            </span><br />
            <span>Invoice Number:
              <span className="value"> {transaction.invoice_number}</span>
            </span><br />
            <span>First Name:
              <span className="value"> {transaction.first_name}</span>
            </span><br />
            <span>Last Name:
              <span className="value"> {transaction.last_name}</span>
            </span><br />
            <span>Amount:
              <span className="value">${Number(transaction.amount_charged).toFixed(2)}</span>
            </span><br />
            <span>Email Address:
              <span className="value">{transaction.email}</span>
            </span><br />
            <span>Order ID:
              <span className="value">{transaction.order_id}</span>
            </span><br />
            <span>Date:
              <span className="value">{new Date(transaction.created_at).toDateString()}</span>
            </span><br />
          </div>
          <div className="conditions-policy">
            <Link to='/payments' style={{ fontSize: '1rem' }}>Make Payment</Link>
          </div>
        </div>
      </Template>
    )
  }
}

export default withRouter(
  connect(state => ({
    transaction: state.payments.transaction,
  }), {})(Success)
)
