import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'

import Template from './templates/default'
import CardImage from '../assets/images/payment-screen-card.jpg'
import { BigRedCross } from './base64-images'

const ErrorScreen = ({ error }) => (
  <Template image={CardImage}>
    <div className='text-center'>
      <img src={BigRedCross} alt='Red Cross Sign' style={{ width: '3rem' }} />
      <br />
      <span className='title'>Error</span>
      <div className='response-error'>
        <span className='value'>{error}</span>
      </div>
      <div className='conditions-policy'>
        <Link to='/payments' style={{ fontSize: '1rem' }}>Make Payment</Link>
      </div>
    </div>
  </Template>
)

export default withRouter(
  connect(state => ({
    error: state.payments.error
  }), {})(ErrorScreen)
)
