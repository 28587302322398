import { combineReducers } from "redux"
import { routerReducer } from "react-router-redux"
import payments from './payments';



export default combineReducers({
  payments: payments,
  router: routerReducer
})

