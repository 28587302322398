import React from 'react'

export const Term = () => (
  <div>
    <div className='modTitle'>Terms and Conditions</div>
    <table>
      <tbody>
        <tr>
          <td valign='top'>
            <p>By using&nbsp;this&nbsp;site,&nbsp;you&nbsp;agree&nbsp;to&nbsp;these terms&nbsp;and&nbsp;conditions:&nbsp;</p>
            <p>The Government&nbsp;of&nbsp;The&nbsp;Bahamas&nbsp;is&nbsp;given permission&nbsp;to&nbsp;use&nbsp;your&nbsp;personal&nbsp;information provided&nbsp;at&nbsp;this&nbsp;site,&nbsp;and&nbsp;any&nbsp;information submitted&nbsp;to&nbsp;other&nbsp;Government&nbsp;sources&nbsp;necessary to&nbsp;process&nbsp;any&nbsp;application&nbsp;requested&nbsp;on&nbsp;this site.</p>
            <p>By registering&nbsp;at&nbsp;this&nbsp;site,&nbsp;you&nbsp;are responsible&nbsp;for&nbsp;maintaining&nbsp;the&nbsp;confidentiality of&nbsp;your&nbsp;account&nbsp;and&nbsp;password,&nbsp;and&nbsp;for ensuring&nbsp;that&nbsp;the&nbsp;computer&nbsp;you&nbsp;use&nbsp;to access&nbsp;this&nbsp;site&nbsp;is&nbsp;secure.</p>
            <p>How You Can Help Protect Your Privacy &nbsp;</p>
            <ol>
              <li>Do not share your account information and passwords with others.</li>
              <li>Never provide confidential information to unknown Government agents.</li>
              <li>When conducting business over the Internet, always use a secure browser and exit online applications as soon as you finish using them.</li>
              <li>Protect your account records.</li>
              <li>If you are a victim of fraud or identity theft, please contact the relevant agency and contact us using one of the following:</li>
            </ol>
            <ul type='disc'>
              <li>Use our &nbsp;<a href='https://www.bahamas.gov.bs/wps/portal/public/system/terms/!ut/p/b1/04_Sj9CPykssy0xPLMnMz0vMAfGjzOKNDdx9HR1NLHzdTQ3MDDxDA3wcA8w9jPy9jYAKIoEKDHAARwNC-sP1o_AqMTGAKsBjhZ9Hfm6qfkFuhEGWiaMiALGJzU0!/dl4/d5/L2dJQSEvUUt3QS80SmtFL1o2X1FCMFFTTDkzQTBBNTUwSUgyVDc3R1UzQ0Yw/?1dmy&urile=wcm%3apath%3a/mof_content/internet/customer+service/customer+service' >Customer service</a>&nbsp;application</li>
              <li>Call us at &nbsp;242-322-4151</li>
              <li>Send us an email at &nbsp;<a href='mailto:support@bahamas.gov.bs' target='_blank' rel='noopener noreferrer'>support@bahamas.gov.bs</a></li>
            </ul>
            <p>Persons under&nbsp;the&nbsp;age&nbsp;of&nbsp;18&nbsp;may&nbsp;register&nbsp;at this&nbsp;site&nbsp;only&nbsp;with&nbsp;the&nbsp;consent&nbsp;of&nbsp;a parent&nbsp;or&nbsp;legal&nbsp;guardian.</p>
            <p>You will&nbsp;not&nbsp;impersonate&nbsp;any&nbsp;person&nbsp;or&nbsp;present false&nbsp;information&nbsp;to&nbsp;register&nbsp;at&nbsp;this&nbsp;site, and&nbsp;you&nbsp;are&nbsp;responsible&nbsp;for&nbsp;the accuracy&nbsp;of&nbsp;the&nbsp;information&nbsp;you&nbsp;provide.</p>
            <p>Any content&nbsp;posted&nbsp;at&nbsp;this&nbsp;site&nbsp;must&nbsp;not be illegal,&nbsp;obscene,&nbsp;threatening or defamatory.&nbsp;</p>
            <p>All transactions&nbsp;on&nbsp;this&nbsp;site&nbsp;will&nbsp;be&nbsp;logged and&nbsp;audited,&nbsp;and&nbsp;are&nbsp;bound&nbsp;by&nbsp;the laws&nbsp;of&nbsp;The&nbsp;Bahamas.</p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
)

export const Privacy = () => (
  <div>
    <div className='modTitle'><br />Privacy Policy</div>
    <div className='modTitle'>
      <p className='introPara'>The Ministry of Finance is committed to ensuring that your privacy is protected.</p>
      <p>Any personal information you do provide to us will be kept for as long as necessary to achieve the purpose we collected it for. If your personal information becomes part of your Ministry of Finance records, we will retain the information in accordance with Ministry of Finance policy on record retention.</p>
      <p>To protect against identity theft and to protect your privacy online, the Ministry of Finance recommends the following precautions:</p>
      <ul>
        <li>Use strong passwords that have:
          <ul>
            <li>at least eight characters.</li>
            <li>a mixture of numbers, letters and symbols.</li>
            <li>both upper and lower cases.</li>
          </ul>
        </li>
        <li>Have a different password for each website you use.</li>
        <li>Never share your passwords with anyone - even someone you trust might accidentally give away your password.</li>
        <li>Never write your passwords down.</li>
        <li>Before you enter your password check that the website address begins with 'https' (the 's' stands for secure).</li>
        <li>When accessing important accounts like the Ministry of Finance or your bank, always type the address into the address bar yourself and&nbsp;<strong>check it</strong>&nbsp;before you press enter. This will guard against you clicking a fake link that may redirect you to a malicious website.</li>
      </ul>
    </div>
  </div>
)