import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import './index.css'
import App from './App';
import * as serviceWorker from './serviceWorker';


export const history = createBrowserHistory();


//  app and admin routes
ReactDOM.render(

    
    <Router history={history}>
      <Switch>
        <Route path="/" component={App} />
      </Switch>
    </Router>,
  document.getElementById('root')
);


serviceWorker.unregister();