import React from 'react'
import moment from 'moment'
import { ValidatorForm } from 'react-form-validator-core'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import Loader from './helpers/Loader'
import Field from './fields'

import { createInvoice } from '../actions/payment'

import Template from './templates/default'
import CardImage from '../assets/images/sample-vaccination-card.jpg'

const pageBackground = '#0E7E87'

const styles = {
  text: {
    fontSize: '1rem',
  }
}

const emptyForm = () => ({
  notes: {},
  amount: 10,
  first_name: '',
  last_name: '',
  email: '',
  mobile_number: '',
  revenue_line_item_id: '425',
  transaction_mode: 'vax',
})

const stripNonNumbers = val => val.replace(/[^0-9]/g, '')

class VaccinePayment extends React.Component {
  constructor (props) {
    super(props)

    ValidatorForm.addValidationRule('isPhone', val => {
      const digits = stripNonNumbers(val)
      const numDigits = digits.length

      if (/[^0-9() +-]/i.test(val)) return false
      if (numDigits < 7) return false
      if (numDigits === 7 && !digits.startsWith('242')) return true
      if (numDigits < 10) return false

      return true
    })

    ValidatorForm.addValidationRule('isBahamianPhone', val => {
      const digits = stripNonNumbers(val)
      const numDigits = digits.length

      if (numDigits === 7) return true
      if (numDigits === 10 && !digits.startsWith('242')) return false

      return true
    })
  }

  state = {
    form: emptyForm(),
    error: null,
    invoice: null,
    loading: false,
    contact_method: '',
  }

  componentWillReceiveProps({ loading, error }) {
    this.setState({ loading, error })
  }

  on = {
    reset: () => this.setState({ invoice: null, form: emptyForm() }),

    change: ({ target }) => this.setState(state => {
      state.form[target.name] = target.value
      return state
    }),

    notes: ({ target }) => this.setState(state => {
      state.form.notes[target.name] = target.value
      return state
    }),

    select: name => opt => opt.length && this.setState(state => {
      const val = opt[0]['value']
      state.form.notes[name] = val
      return state
    }),

    contact: ({ target }) => this.setState(state => {
      state.contact_method = target.value

      if (target.value === 'Email Address') {
        state.form.mobile_number = ''
      } else {
        state.form.email = ''
      }

      return state
    }),

    doseDate: date => this.setState(state => {
      state.form.notes.second_dose_date = date
      return state
    }),

    error: e => {
      let isValid = Object.assign({}, this.state.isValid)
      isValid[e.inputName] = false
      this.setState({ error: e.error, isValid: isValid })
    },
  }

  formatDate = date => date ? (
    typeof date == 'string'
      ? new Date(date.replace(/^(.{4})(.{2})(.{2})$/, '$1-$2-$3'))
      : date
  ) : null

  submit = e => {
    e.preventDefault()

    const invoice = (() => ({ ...this.state.form }))()

    invoice.notes = `
      Second Dose Date: ${moment(invoice.notes.second_dose_date).format('Do MMMM, YYYY')}
      ,
      Second Dose Location: ${invoice.notes.second_dose_location}
      ,
      Card Pickup Location: ${invoice.notes.pickup_location}
    `.replace(/\s{2,}/g, ' ')

    if (invoice.notes.bcv_code) {
      invoice.notes += `, BCV Code: ${invoice.notes.bcv_code}`
    }

    if (invoice.mobile_number) {
      invoice.email = 'digipayusers@bahamas.gov.bs'
      invoice.mobile_number = stripNonNumbers(invoice.mobile_number)

      if (invoice.mobile_number.length === 7) {
        invoice.mobile_number = '1242' + invoice.mobile_number
      }

      if (!invoice.mobile_number.startsWith('1')) {
        invoice.mobile_number = '1' + invoice.mobile_number
      }
    }

    this.props.createInvoice(invoice, (error, invoice) => {
      this.setState({ error, invoice })
    })
  }

  render () {
    const { state, props, on, formatDate, submit } = this

    const methods = ['Email Address', 'Mobile Phone Number'].map(n => ({ value: n, label: n }))
    const doseDate = formatDate(state.form.notes.second_dose_date)

    const locations = [
      'Kendal G.L. Isaacs Gymnasium',
      'Loyola Hall, Gladstone Rd.',
      'Church of God of Prophecy, East St.',
      'St. Anselm’s Church Hall, Bernard Rd.',
      'Susan J. Wallace Community Centre, Freeport, Grand Bahama',
    ].map(n => ({ value: n, label: n }))

    let contact = null, component = null

    if (state.contact_method === 'Email Address') {
      contact = (
        <div className='form-group'>
          <Field
            name='email'
            type='text'
            label='Email Address'
            onChange={on.change}
            value={state.form.email}
            validators={['required', 'isEmail']}
            errorMessages={['Required', 'Invalid Email']}
          />
        </div>
      )
    } else if (state.contact_method === 'Mobile Phone Number') {
      contact = (
        <div className='form-group'>
          <Field
            name='mobile_number'
            type='text'
            label='Phone Number'
            value={state.form.mobile_number}
            onChange={on.change}
            validators={['required', 'isPhone', 'isBahamianPhone']}
            errorMessages={['Required', 'Not a valid phone number', 'Must use a 242 number']}
          />
        </div>
      )
    }

    if (state.invoice) {
      component = (
        <div className='d-flex flex-column align-items-center'>
          <h5 style={{ margin: '1rem auto 2.5rem' }}>Thank you for your submission.</h5>
          <span style={{ fontSize: '1rem', color: pageBackground }} onClick={on.reset}>Request Another Card</span>
        </div>
      )
    } else if (state.error) {
      component = (
        <div className='d-flex flex-column align-items-center'>
          <h5 style={{ margin: '1rem auto 2.5rem' }}>Sorry,</h5>
          <p style={{ fontSize: '1rem' }}>There was an error processing your request. please try again later.</p>
        </div>
      )
    } else {
      component = (
        <ValidatorForm ref={'form'} method='post' onSubmit={submit}>
          <div className='row'>
            <div className='col-md-6'>
              <div className="form-group">
                <Field
                  name='first_name'
                  type='text'
                  onChange={on.change}
                  value={state.form.first_name}
                />
              </div>
            </div>

            <div className='col-md-6'>
              <div className="form-group">
                <Field
                  name='last_name'
                  type='text'
                  onChange={on.change}
                  value={state.form.last_name}
                />
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-6'>
              <div className="form-group">
                <Field
                  name='second_dose_date'
                  type='date'
                  selected={doseDate}
                  value={doseDate}
                  onChange={on.doseDate}
                  showYearDropdown={true}
                  dateFormat='do MMMM, yyyy'
                  dropdownMode='select'
                />
              </div>
            </div>

            <div className='col-md-6'>
              <div className="form-group">
                <Field
                  name='second_dose_location'
                  type='select.native'
                  options={locations}
                  onChange={on.notes}
                  value={state.form.notes.second_dose_location}
                />
              </div>
            </div>
          </div>

          <div className='form-group'>
            <Field
              name='bcv_code'
              type='text'
              label='BCV Code'
              value={state.form.bcv_code}
              onChange={on.notes}
            />
          </div>

          <div className='form-group'>
            <Field
              name='pickup_location'
              type='select.native'
              options={locations.slice(1, 3)}
              onChange={on.notes}
              value={state.form.notes.pickup_location}
            />
          </div>

          <div className='form-group'>
            <Field
              name='contact_method'
              type='select.native'
              options={methods}
              onChange={on.contact}
              value={state.contact_method}
            />
          </div>

          {contact}

          <div className='form-group form-action-d-flex mb-3'>
            <button type='submit' className='paybtn btn col-md-5 mt-3 mt-sm-0 fw-bold text-white' style={{ background: pageBackground }}>Register</button>
          </div>
        </ValidatorForm>
      )
    }

    const subtitle = (
      <React.Fragment>
        <p className='text-white text-justify' style={styles.text}>
          To pre-order your Government of the Commonwealth of The Bahamas COVID-19 Vaccination Card,
          fill out the form below and hit submit to receive an invoice for payment and arrange collection
          of your card at one of the designated vaccination centers. It's that easy.
        </p>
        <br/>
        <p className='text-white text-justify' style={styles.text}>
          Part proceeds to benefit the Bahamas Girl Guides Association Headquarters Building Fund.
        </p>
      </React.Fragment>
    )

    return (
      <React.Fragment>
        <ToastContainer />
        
        <Template
          background={pageBackground}
          image={CardImage}
          imageAlt='Sample Vaccination Card'
          title='Government of The Bahamas COVID-19 Vaccination Programme'
          subtitle={subtitle}
        >
          <Loader loading={props.loading || state.loading} />
          {component}
        </Template>
      </React.Fragment>
    )
  }
}

export default withRouter(
  connect(({ payments }) => ({
    loading: payments.loading,
    success_message: payments.success_message,
    error: payments.error
  }), {
    createInvoice,
  })(VaccinePayment)
)
