import $ from 'jquery'
import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'
import Modal from 'react-responsive-modal'
import Loader from './helpers/Loader'
import CreditCardInput from 'react-credit-card-input'
import CreditCardType from 'credit-card-type'
import { ValidatorForm } from 'react-form-validator-core'

import Field from './fields'
import { Term, Privacy } from './terms-and-privacy'
import { saveTransaction, createInvoice } from '../actions/payment'

import Template from './templates/default'
import { extractKeys } from './helpers/Tools'
import CardImage from '../assets/images/rpt-screen-card.jpg'

import { BigCheckmark } from './base64-images'
import PhoneIcon from '../assets/images/icons/phone.svg'
import EmailIcon from '../assets/images/icons/envelope.svg'
import HCaptcha from '@hcaptcha/react-hcaptcha'
import { ToastContainer, toast } from 'react-toastify'


/* eslint-disable */
// These variables are referenced by an eval statement
// that is used to get the correct card type image
const unionpay = require('../assets/images/cards/unionpay.png')
const discover = require('../assets/images/cards/discover.png')
const visa = require('../assets/images/cards/visa.png')
const mastercard = require('../assets/images/cards/mastercard.png')
const american = require('../assets/images/cards/american_express.png')
const american_express = require('../assets/images/cards/american_express.png')
const jcb = require('../assets/images/cards/jcb.png')
const maestro = require('../assets/images/cards/maestro.png')
const diners_club = require('../assets/images/cards/dinners_club.png')
const mir = ''
/* eslint-enable */

const freshForm = () => ({
  form: {
    reference_code: '',
    first_name: '',
    last_name: '',
    email: '',

    'card-name': '',
    'card-amount': '',
    'card-number': '',
    'card-exp': '',
    'card-cvv': '',
  },

  valid: {
    cardNumber: false,
    cardExpiry: false,
    cardCVC: false
  },
})

const pageBackground = '#0E7E87'

class RealPropertyTaxPayment extends React.Component {
  state = {
    open: false,
    modal_state: null,
    ...freshForm(),
    captchaToken: null,

    invoice: null,
  }

  line_item = 263
  subtitle = (
    <p className='subtitle text-white op-7'>Pay your real property tax online.</p>
  )

  on = {
    reset: () => this.setState({
      success: null,
      invoice: null,
      form: freshForm()
    }),

    input: ({ target }) => this.setState(state => {
      state.form[target.name] = target.value
      return state
    }),

    select: name => data => data?.length && this.setState(state => {
      state.form[name] = data[0].value
      return state
    }),

    modal: {
      open: modal_state => () => this.setState({ open: true, modal_state }),
      close: () => this.setState({ open: false, modal_state: null }),
    }
  }

  cc = {
    number: ({ target }) => this.setState(state => {
      const type = CreditCardType(target.value)
      // eslint-disable-next-line
      $('.sc-htpNat').attr('src', eval(type[0]?.type?.replace('-', '_')))

      state.form['card-number'] = target.value
      state.valid.cardNumber = true

      return state
    }),

    exp: ({ target }) => this.setState(state => {
      state.form['card-exp'] = target.value
      state.valid.cardExpiry = true
      return state
    }),

    cvv: ({ target }) => this.setState(state => {
      state.form['card-cvv'] = target.value
      state.valid.cardCVC = true
      return state
    }),

    error: err => this.setState(state => {
      state.valid[err.inputName] = false
      return state
    }),
  }

  onVerifyCaptcha = (token) => {
    this.setState({ captchaToken: token })
  }

  attemptSubmission = async ev => {
    ev.preventDefault()

    // HCaptcha Token
    const { captchaToken } = this.state;
    const { form, valid } = this.state

    const common = {
      'transaction_mode': 'rpt',
      'revenue_line_item_id': this.line_item,
    }

    if (Object.values(valid).includes(false)) return
    if (!captchaToken){
      toast.error('Please solve hCaptcha')

      return
    }

    const submit = onClose => () => {
      this.props.createInvoice({
        skip_email: true,
        ...common,
        ...extractKeys(
          form,
          'first_name',
          'last_name',
          'email',
          'card-amount:amount',
          'reference_code',
        ),
      }, (error, invoice) => {
        if (error) {
          this.setState({ error })
          return
        }

        this.props.saveTransaction({
          ...common,
          ...form,
          'invoice_number': invoice.invoice_number,
          'publisher-name': 'pnpdemo',
        }, success => {
          success && this.setState({
            invoice,
            success,
            error: null,
          })
        })
      })

      onClose()
    }

    confirmAlert({
      customUI: ({ onClose }) => (
        <div className='custom-ui'>
          <h3>Are you sure that you'd like to make this request?</h3>
          <button onClick={onClose}>No</button>
          <button onClick={submit(onClose)}>Yes</button>
        </div>
      )
    })
  }

  render () {
    const { state, props, on, cc, attemptSubmission } = this
    const { form, invoice, open, modal_state } = state
    const { transaction } = props

    let component

    if (state.error || props.error) {
      component = (
        <div className='d-flex flex-column align-items-center'>
          <h5 style={{ margin: '1rem auto 2.5rem' }}>Sorry,</h5>
          <p style={{ fontSize: '1rem' }}>There was an error processing your request. please try again later.</p>
        </div>
      )
    } else if (state.success) {
      const values = [
        ['Revenue Line Item ID', transaction.description],
        ['Assessment Number', form.reference_code],
        ['Invoice Number', invoice.invoice_reference],
        ['Name', `${form.first_name} ${form.last_name}`],
        ['Amount', '$' + form['card-amount']],
        ['Email', form.email],
        ['Order ID', transaction.order_id],
        ['Date', moment(transaction.created_at).format('Do MMMM, YYYY')]
      ].map(([heading, value], index) => (
        <div key={index} className='d-flex'>
          <span>{heading}:</span>
          <span>{value}</span>
        </div>
      ))

      component = (
        <div className='d-flex flex-column align-items-center'>
          <img src={BigCheckmark} alt='Check Icon' style={{ width: '3rem' }} />
          <span className="title">Success</span>
          <div className='response-data w-100'>{values}</div>
          <span
            style={{fontSize: '1rem', color: pageBackground, cursor: 'pointer' }}
            onClick={on.reset}
          >Make Another Payment</span>
        </div>
      )
    } else {
      component = (
        <ValidatorForm onSubmit={attemptSubmission}>
          <div style={{ margin: '1rem 0 -0.75rem' }}>Property Details</div>
          <hr />

          <div className='form-group'>
            <Field
              name='reference_code'
              label='Assessment Number'
              type='text'
              onChange={on.input}
              value={form.reference_code}
            />
          </div>

          <div style={{ margin: '2rem 0 -0.75rem' }}>Client Details</div>
          <hr />

          <div className='row'>
            <div className='col-md-6'>
              <div className="form-group">
                <Field
                  name='first_name'
                  type='text'
                  onChange={on.input}
                  value={form.first_name}
                />
              </div>
            </div>

            <div className='col-md-6'>
              <div className="form-group">
                <Field
                  name='last_name'
                  type='text'
                  onChange={on.input}
                  value={form.last_name}
                />
              </div>
            </div>
          </div>

          <div className='form-group'>
            <Field
              name='email'
              type='text'
              onChange={on.input}
              value={form.email}
              validators={['required', 'isEmail']}
              errorMessages={['This field is required', 'Invalid Email']}
            />
          </div>

          <div style={{ margin: '2rem 0 -0.75rem' }}>Credit Card Details</div>
          <hr />

          <div className='form-group'>
            <Field
              name='card-name'
              type='text'
              label='Card Holder Name'
              onChange={on.input}
              value={form['card-name']}
            />
          </div>

          <div className='form-group cc-input'>
            <label className='placeholder'>Card Info <span className='required-label'>*</span></label>
            <CreditCardInput
              cardNumberInputProps={{ value: form['card-number'], onChange: cc.number }}
              cardExpiryInputProps={{ value: form['card-exp'], onChange: cc.exp }}
              cardCVCInputProps={{ value: form['card-cvv'], onChange: cc.cvv }}
              onError={cc.error}
              fieldClassName='input placeholder'
            />
          </div>

          <div className='form-group static-card clerfix'>
            <img alt='Master Card' src={require('../assets/images/master_card.png')} />
            &nbsp;
            <img alt='Visa Card' src={require('../assets/images/visa.png')} />
          </div>

          <div className='form-group'>
            <Field
              name='card-amount'
              label='Amount (BSD)'
              type='text'
              onChange={on.input}
              value={form['card-amount']}
              inputMode='decimal'
              validators={['required', 'isFloat', 'matchRegexp:^[1-9][0-9]{0,}(\.[0-9]{2})?$']} // eslint-disable-line
              errorMessages={['This field is required', 'This field should be numeric', 'Invalid Currency Amount']}
            />
          </div>

          <div className='mt-4'>
            <HCaptcha
              sitekey="89f80718-aac2-48a8-b7f8-31363506bd32"
              // onLoad={onLoad}
              onVerify={this.onVerifyCaptcha}
              // ref={captchaRef}
            />
          </div>

          <div className='form-group form-action-d-flex mb-3'>
            <button type='submit' className='paybtn btn col-md-5 mt-3 mt-sm-0 fw-bold text-white' style={{ background: pageBackground }}>Pay</button>
          </div>

          <div className='login-account mb-3'>
            <div className='conditions-policy rpt-support-links'>
              <a href='mailto:propertytaxenquiries@bahamas.gov.bs' className='d-flex-inline align-items-center'>
                <img src={EmailIcon} style={{ width: '1.25rem', fill: pageBackground }} alt='email' />
                &nbsp;
                propertytaxenquiries@bahamas.gov.bs
              </a>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <a href='tel:+12426048253' className='d-flex-inline align-items-center'>
                <img src={PhoneIcon} style={{ width: '1.25rem', fill: pageBackground }} alt='phone' />
                &nbsp;
                +1 (242) 604-8253
              </a>
            </div>
          </div>

          <div className='login-account'>
            <div className='conditions-policy'>
              <span onClick={on.modal.open('term')}>Terms and Conditions</span>
              &nbsp;|&nbsp;
              <span onClick={on.modal.open('privacy')}>Privacy Policy</span>
            </div>
          </div>
        </ValidatorForm>
      )
    }

    return (
      <React.Fragment>
        <ToastContainer />
        <Template
          background={pageBackground}
          image={CardImage}
          imageAlt='Couple embraced in new home'
          title='Real Property Tax Payments'
          subtitle={this.subtitle}
        >
          <Loader loading={props.loading} />
          {component}
        </Template>

        <Modal open={open} onClose={on.modal.close}>
          {modal_state === 'term' ? <Term /> : null}
          {modal_state === 'privacy' ? <Privacy /> : null}
        </Modal>
      </React.Fragment>
    )
  }
}

export default withRouter(
  connect(state => extractKeys(
    state.payments,
    'loading',
    'error',
    'transaction'
  ), {
    saveTransaction,
    createInvoice,
  })(RealPropertyTaxPayment)
)
