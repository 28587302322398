import $ from 'jquery'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'
import Modal from 'react-responsive-modal'
import Loader from './helpers/Loader'
import CreditCardInput from 'react-credit-card-input'
import CreditCardType from 'credit-card-type'
import { ValidatorForm } from 'react-form-validator-core'
import { ToastContainer, toast } from 'react-toastify'
import HCaptcha from '@hcaptcha/react-hcaptcha'

import Field from './fields'
import { Term, Privacy } from './terms-and-privacy'
import { saveTransaction, getParticularRevenue } from '../actions/payment'

import Template from './templates/default'
import CardImage from '../assets/images/payment-screen-card.jpg'

/* eslint-disable */
// These variables are referenced by an eval statement
// that is used to get the correct card type image
const unionpay = require('../assets/images/cards/unionpay.png')
const discover = require('../assets/images/cards/discover.png')
const visa = require('../assets/images/cards/visa.png')
const mastercard = require('../assets/images/cards/mastercard.png')
const american = require('../assets/images/cards/american_express.png')
const american_express = require('../assets/images/cards/american_express.png')
const jcb = require('../assets/images/cards/jcb.png')
const maestro = require('../assets/images/cards/maestro.png')
const diners_club = require('../assets/images/cards/dinners_club.png')
const mir = ''
/* eslint-enable */

const freshForm = () => ({
  form: {
    first_name: '',
    last_name: '',
    email: '',
    invoice_number: '',

    'card-name': '',
    'card-number': '',
    'card-exp': '',
    'card-cvv': '',
  },

  valid: {
    cardNumber: false,
    cardExpiry: false,
    cardCVC: false
  },
})


class Payment extends React.Component {
  state = {
    open: false,
    modal_state: null,
    ...freshForm(),
    captchaToken: null,
  }

  componentDidMount () {
    const { history, location } = this.props
    if (location.state?.redirected) return

    const go = (close, path) => () => {
      close()

      if (/^https?:/.test(path)) {
        window.location = path
      } else {
        history.push(path, { redirected: true })
      }
    }

    confirmAlert({
      closeOnClickOutside: false,
      closeOnEscape: false,

      customUI: ({ onClose }) => (
        <div className='custom-ui'>
          <h3>Will you be making a Credit/Debit Card or Sand Dollar payment today?</h3>
          <button onClick={go(onClose, location.pathname)}>Credit/Debit Card</button>
          <button onClick={go(onClose, process.env.REACT_APP_SANDDOLLAR_PYMT)}>Sand Dollar</button>
        </div>
      )
    })
  }

  componentWillReceiveProps ({ error }) {
    this.setState({ error })
  }

  onVerifyCaptcha = (token) => {
    this.setState({ captchaToken: token })
  }

  on = {
    input: ({ target }) => this.setState(state => {
      state.form[target.name] = target.name === 'invoice_number' ? target.value.trim() : target.value
      return state
    }),

    select: name => data => data?.length && this.setState(state => {
      state.form[name] = data[0].value
      return state
    }),

    modal: {
      open: modal_state => () => this.setState({ open: true, modal_state }),
      close: () => this.setState({ open: false, modal_state: null }),
    }
  }

  cc = {
    number: ({ target }) => this.setState(state => {
      const type = CreditCardType(target.value)
      // eslint-disable-next-line
      $('.sc-htpNat').attr('src', eval(type[0]?.type?.replace('-', '_')))

      state.form['card-number'] = target.value
      state.valid.cardNumber = true

      return state
    }),

    exp: ({ target }) => this.setState(state => {
      state.form['card-exp'] = target.value
      state.valid.cardExpiry = true
      return state
    }),

    cvv: ({ target }) => this.setState(state => {
      state.form['card-cvv'] = target.value
      state.valid.cardCVC = true
      return state
    }),

    error: err => this.setState(state => {
      state.valid[err.inputName] = false
      return state
    }),
  }

  attemptSubmission = ev => {
    ev.preventDefault()

      // HCaptcha Token
    const { captchaToken } = this.state;
    const { form, valid } = this.state
    const { line_item, invoice_amount, history } = this.props

    if (Object.values(valid).includes(false)) return
    if (!captchaToken){
      toast.error('Please solve hCaptcha')

      return
    }


    const submit = onClose => () => {
      if (line_item) {
        this.props.saveTransaction({
          ...form,
          'publisher-name': 'pnpdemo',
          'transaction_mode': 'card',
          'card-amount': invoice_amount.toFixed(2),
          'revenue_line_item_id': line_item.id,
        }, success => {
          history.push(success ? '/success' : '/error')
          success && this.setState({ ...freshForm() })
        })
      } else {
        toast.error('Please check the invoice number. We do not have a record to match that on file.')
      }

      onClose()
    }

    confirmAlert({
      customUI: ({ onClose }) => (
        <div className='custom-ui'>
          <h3>Are you sure that you'd like to make this request?</h3>
          <button onClick={onClose}>No</button>
          <button onClick={submit(onClose)}>Yes</button>
        </div>
      )
    })
  }

  getRevenue = ({ target }) => target.value && this.props.getParticularRevenue(target.value)

  render () {
    const { state, props, on, cc, getRevenue, attemptSubmission } = this
    const { form, open, modal_state } = state

    return (
      <React.Fragment>
        <ToastContainer />

        <Template image={CardImage}>
          <Loader loading={props.loading} />
          <ValidatorForm onSubmit={attemptSubmission}>
            <div style={{ margin: '1rem 0 -0.75rem' }}>Invoice Details</div>
            <hr />

            <div className='form-group'>
              <Field
                name='invoice_number'
                type='text'
                onChange={on.input}
                onBlur={getRevenue}
                value={form.invoice_number}
              />
            </div>

            <div className='form-group'>
              <Field
                name='revenue_line_item'
                type='text'
                value={props.line_item?.long_desc || ''}
                readOnly
              />
            </div>

            <div className='form-group'>
              <Field
                label='Amount (BSD)'
                name='card-amount'
                type='text'
                value={props.invoice_amount || '0'}
                readOnly
              />
            </div>

            <div style={{ margin: '2rem 0 -0.75rem' }}>Client Details</div>
            <hr />

            <div className='row'>
              <div className='col-md-6'>
                <div className="form-group">
                  <Field
                    name='first_name'
                    type='text'
                    onChange={on.input}
                    value={form.first_name}
                  />
                </div>
              </div>

              <div className='col-md-6'>
                <div className="form-group">
                  <Field
                    name='last_name'
                    type='text'
                    onChange={on.input}
                    value={form.last_name}
                  />
                </div>
              </div>
            </div>

            <div className='form-group'>
              <Field
                name='email'
                type='text'
                onChange={on.input}
                value={form.email}
                validators={['required', 'isEmail']}
                errorMessages={['This field is required', 'Invalid Email']}
              />
            </div>

            <div style={{ margin: '2rem 0 -0.75rem' }}>Credit Card Details</div>
            <hr />

            <div className='form-group'>
              <Field
                name='card-name'
                type='text'
                label='Card Holder Name'
                onChange={on.input}
                value={form['card-name']}
              />
            </div>

            <div className='form-group cc-input'>
              <label className='placeholder'>Card Info <span className='required-label'>*</span></label>
              <CreditCardInput
                cardNumberInputProps={{ value: form['card-number'], onChange: cc.number }}
                cardExpiryInputProps={{ value: form['card-exp'], onChange: cc.exp }}
                cardCVCInputProps={{ value: form['card-cvv'], onChange: cc.cvv }}
                onError={cc.error}
                fieldClassName='input placeholder'
              />
            </div>

            <div className='form-group static-card clerfix'>
              <img alt='Master Card' src={require('../assets/images/master_card.png')} />
              &nbsp;
              <img alt='Visa Card' src={require('../assets/images/visa.png')} />
            </div>

            <div className='mt-4'>
              <HCaptcha
                sitekey="89f80718-aac2-48a8-b7f8-31363506bd32"
                // onLoad={onLoad}
                onVerify={this.onVerifyCaptcha}
                // ref={captchaRef}
              />
            </div>

            <div className='form-group form-action-d-flex mb-3'>
              <button type='submit' className='paybtn btn btn-secondary col-md-5 float-right mt-3 mt-sm-0 fw-bold'>Pay</button>
            </div>

            <div className='login-account'>
              <div className='conditions-policy'>
                <span onClick={on.modal.open('term')}>Terms and Conditions</span>
                &nbsp;|&nbsp;
                <span onClick={on.modal.open('privacy')}>Privacy Policy</span>
              </div>
            </div>
          </ValidatorForm>
        </Template>

        <Modal open={open} onClose={on.modal.close}>
          {modal_state === 'term' ? <Term /> : null}
          {modal_state === 'privacy' ? <Privacy /> : null}
        </Modal>
      </React.Fragment>
    )
  }
}

export default withRouter(
  connect(state => ({
    loading: state.payments.loading,
    line_item: state.payments.line_item,
    invoice_amount: state.payments.invoice_amount,
    error: state.payments.error
  }), {
    saveTransaction,
    getParticularRevenue
  })(Payment)
)
