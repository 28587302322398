import React from 'react'

import Input from './InputField'
import Select from './SelectField'
import NativeSelect from './SelectField.Native'
import DateField from './DateField'

const fields = {
  text   : Input,
  select : Select,
  date   : DateField,

  'select.native' : NativeSelect,
}

const pretty = str => str
  .split('_')
  .map(w => w.charAt(0).toUpperCase() + w.slice(1))
  .join(' ')
  .replace(' Id', ' ID')

export default function Field ({ name, type, label, required, children, ...rest }) {
  let input = fields[type]
  if (rest.options && !input) input = fields.select

  const props = input && {
    className: 'form-control',
  }

  if (props) {
    if (type !== 'select') Object.assign(props, { name, type })

    if (required !== false) {
      Object.assign(props, {
        validators: ['required'],
        errorMessages: ['This field is required']
      })
    }

    if (type === 'text') props.placeholder = label || pretty(name)
    if (type === 'date') props.placeholderText = label || pretty(name)


    // Automatically set common input modes
    if (name === 'email') props.inputMode = 'email'
    if (name === 'mobile_number') props.inputMode = 'tel'
  
    // Lastly assign rest to override default niceties
    Object.assign(props, rest)
  }

  return (
    <div className='form-show-validation'>
      <label htmlFor={name} className='placeholder'>
        {label || pretty(name)}
        {required !== false ? <span className="required-label">*</span> : null}
      </label>
      <div>
        {input ? React.createElement(input, props) : null}
        {children || null}
      </div>
    </div>
  )
}